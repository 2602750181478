// Worx. Copyright(c) 2017-2021 Wonder Works Construction Corp. All rights reserved.

function isTaxId(id) {
    id = id.trim();
    return id.match(/^\d{2}-\d{7}$/) !== null;
}

function isEmail(email) {
    email = email.trim();
    return email !== "" && validator.isEmail(email);
}

const ACCEPTED_DOCUMENT_TYPES_DISPLAY = ".doc, .pdf, .jpg, .png files";
const ACCEPTED_DOCUMENT_TYPES = [
    "application/msword",
    "image/gif",
    "image/jpeg",
    "image/png",
    "application/pdf"
];

$(document).ready(function() {
    var formActive = false;
    var questions = $(".apply-section-content").toArray().map(function(elem) {
        return $(elem);
    });

    var navPrev = $("#prev-question");
    var navNext = $("#next-question");
    var visibleQuestions = 0;
    var nextMoveTimeout = null;
    var choiceDelay = 500;

    var isFilled = function(question) {
        var jq = $(question);
        if(jq.find(".uploaded").length > 0) {
            return true;
        }
        else if(jq.find(".choice").length > 0) {
            return true;
        }
        else if(jq.find(".agree").length > 0) {
            return true;
        }
        else if(jq.find(".selection-made").length > 0) {
            return true;
        }
        else if(jq.find(".apply-confirm-choice").length > 0 && jq.find(".apply-confirm-choice-selected").length === 3) {
            return true;
        }
        else if(jq.find(".apply-confirm-patch").length > 0 && jq.find(".apply-confirm-choice-selected").length === 1) {
            return true;
        }
        else if(jq.find(".agree-button").length === 0 && jq.find(".ok-hint").length > 0 && jq.find(".ok-hint-disabled").length === 0) {
            return true;
        }
        return false;
    };

    var validateAnswer = function(question) {
        if(!isFilled(question)) {
            if(question.find(".agree-button").length > 0) {
                return "You must click the button.";
            }
            else if(question.find(".apply-choice").length > 0) {
                return "You must pick an option.";
            }
            else if(question.find(".apply-dropzone").length > 0) {
                return "You must select a document.";
            }
            else if(question.find(".apply-select").length > 0) {
                return "You must select an option.";
            }
            else if(question.find(".apply-confirm-choice").length > 0) {
                return "You must select all yes or no options.";
            }
            else {
                if(question.find(".apply-required").length === 1) {
                    return "This field is required.";
                }
                else {
                    return "Please fill out the required fields.";
                }
            }
        }
        else {
            var emails = question.find(".validate-email");
            if(emails.length > 0 && !isEmail(emails.val())) {
                return "Invalid email address.";
            }
            var taxIds = question.find(".validate-tax-id");
            if(taxIds.length > 0 && !isTaxId(taxIds.val())) {
                return "Invalid tax id.";
            }
        }
        return null;
    };

    var checkQuestion = function(question, animate) {
        if(animate === undefined || animate === null) {
            animate = true;
        }

        var errorTip = question.find(".apply-errortip");
        errorTip.hide();

        var errorMessage = validateAnswer(question);
        if(errorMessage !== null) {
            errorTip.text(errorMessage);
            if(animate) {
                errorTip.show(200);
            }
            else {
                errorTip.show();
            }
            return false;
        }
        else {
            return true;
        }
    };

    var linkQuestions = function() {
        var prev = null;
        visibleQuestions = 0;
        questions.forEach(function(q) {
            var container = q.parents(".apply-question");

            if($(container).is(":visible")) {
                container.find(".question-num").text("" + (visibleQuestions + 1));
                if(prev !== null) {
                    prev.nextQuestion = q;
                }
                q.prevQuestion = prev;
                prev = q;
                q.questionNumber = visibleQuestions;
                visibleQuestions += 1;
            }
            else {
                if(prev !== null) {
                    prev.nextQuestion = null;
                }
                q.prevQuestion = null;
                q.nextQuestion = null;
            }
        });
    };

    var activeQuestion = null;
    var lastQuestion = null;

    var gotoQuestion = function(question) {
        $("html, body").animate({
            scrollTop: question.offset().top - $(window).height() / 2 + question.height() / 2
        }, 300);
    };
    var gotoSubmit = function() {
        $("html, body").animate({
            scrollTop: $(document).height(),
            complete: function() {
                if(!navNext.hasClass("not-avail")) {
                    navNext.addClass("not-avail");
                }
            }
        }, 300);
    };

    var setActiveQuestion = function(question) {
        var temp = activeQuestion;
        activeQuestion = question;

        if(activeQuestion === null || activeQuestion === undefined) {
            lastQuestion = temp;
            if(navPrev.hasClass("not-avail")) {
                navPrev.removeClass("not-avail");
            }
            if(!navNext.hasClass("not-avail")) {
                navNext.addClass("not-avail");
            }
        }
        else {
            lastQuestion = null;
            if(activeQuestion.prevQuestion === undefined || activeQuestion.prevQuestion === null) {
                if(!navPrev.hasClass("not-avail")) {
                    navPrev.addClass("not-avail");
                }
            }
            else {
                if(navPrev.hasClass("not-avail")) {
                    navPrev.removeClass("not-avail");
                }
            }
            if(navNext.hasClass("not-avail")) {
                navNext.removeClass("not-avail");
            }
        }
    };

    var triggerNextQuestion = function() {
        if(activeQuestion !== null || activeQuestion !== undefined) {
            var errorTip = activeQuestion.find(".apply-errortip");
            errorTip.hide(100);
        }

        // BUG, goes to the submit on first question
        if(activeQuestion === null || activeQuestion === undefined ||
           activeQuestion.nextQuestion === null || activeQuestion.nextQuestion === undefined) {

            setActiveQuestion(null);
            gotoSubmit();
        }
        else {
            gotoQuestion(activeQuestion.nextQuestion);
        }
    };

    var triggerEnterNextQuestion = function(triggerByKeypress) {
        if(triggerByKeypress === undefined || triggerByKeypress === null) {
            triggerByKeypress = true;
        }

        var okHint = activeQuestion.find(".ok-hint");

        // Go to next question only if there's an ok button, that button is not disabled and if triggered by keypress check if the button allows that.
        if(okHint.length >= 1 && !okHint.hasClass("ok-hint-disabled") &&
           (!triggerByKeypress || (triggerByKeypress && !okHint.hasClass("click-required")))) {

            if(okHint.hasClass("agree-button")) {
                if(!okHint.hasClass("agree")) {
                    okHint.addClass("agree");
                }
                nextMoveTimeout = setTimeout(function() {
                    triggerNextQuestion();
                }, choiceDelay);
                updateProgress();
            }
            else {
                if(checkQuestion(activeQuestion)) {
                    triggerNextQuestion();
                }
            }
        }
    };

    var updateProgress = function() {
        var progress = 0;
        var step = 100 / visibleQuestions;
        //var num = 0;
        var numInvalid = 0;

        var errorTip = $(".apply-submit-errortip");

        var q = questions[0];
        while(q !== null && q !== undefined) {
            if(validateAnswer(q) === null) {
                progress += step;
            }
            else {
                numInvalid += 1;
            }
            q = q.nextQuestion;
        }

        if(errorTip.is(":visible")) {
            if(numInvalid === 0) {
                errorTip.hide();
            }
            else {
                if(numInvalid === 1) {
                    errorTip.text("1 field needs completing.");
                }
                else {
                    errorTip.text(numInvalid + " fields needs completing.");
                }
            }
        }

        $("#progress-perc").text(Math.round(progress) + "%");
        $("#progress-bar-fill").css({ width: Math.round(progress) + "%" });
    };


    var addressField = $("input[name=addressSearch]").get(0);
    var lastPlace = null;
    var autocomplete = new google.maps.places.Autocomplete(addressField, {
        types: ["address"]
    });

    $("input[name=addressSearch]").on("input", function() {
        lastPlace = null;
    });

    autocomplete.addListener("place_changed", function() {
        lastPlace = autocomplete.getPlace();

        var state = "";
        var zipCode = "";
        var city = "";
        var country = "";
        var street = "";
        var building = "";

        var addressComponents = lastPlace.address_components;
        if(!addressComponents) {
            return;
        }
        for(var i = 0; i < addressComponents.length; i += 1) {
            var component = addressComponents[i];
            var addressType = component.types[0];
            if(addressType === "administrative_area_level_1") {
                state = component.long_name;
            }
            if(addressType === "country") {
                country = component.long_name;
            }
            else if(addressType === "postal_code") {
                zipCode = component.long_name;
            }
            else if(addressType === "locality") {
                city = component.long_name;
            }
            else if(addressType === "route") {
                street = component.long_name;
            }
            else if(addressType === "street_number") {
                building = component.long_name;
            }
        }

        if(state === undefined || state === null || state === "") {
            state = city;
        }

        $("input[name=address]").val(building + " " + street).trigger("input");
        $("input[name=state]").val(state).trigger("input");
        $("input[name=zipCode]").val(zipCode).trigger("input");
        $("input[name=city]").val(city).trigger("input");
        $("input[name=country]").val(country).trigger("input");

        var phoneNumber = lastPlace.formatted_phone_number;
        if(phoneNumber !== undefined && phoneNumber !== null && phoneNumber !== "") {
            $("input[name=phone]").val(phoneNumber).trigger("input");
        }

        if(country.toLowerCase() === "united states" || country.toLowerCase() === "usa" || country.toLowerCase() === "us") {
            $(".usa-specific").show();
        }
        else {
            $(".usa-specific").hide();
        }

        linkQuestions();
        updateProgress();
    });

    navPrev.on("click", function(e) {
        e.preventDefault();
        if(!navPrev.hasClass("not-avail")) {
            if(activeQuestion === null || activeQuestion === undefined) {
                if(!lastQuestion) {
                    gotoQuestion(questions[questions.length - 1]);
                }
                else {
                    gotoQuestion(lastQuestion);
                }
            }
            else {
                gotoQuestion(activeQuestion.prevQuestion);
            }
        }
    });
    navNext.on("click", function(e) {
        e.preventDefault();
        if(!navNext.hasClass("not-avail")) {
            triggerNextQuestion();
        }
    });

    $(".apply-required").on("input", function() {
        var questionContainer = $(this).parents(".apply-question");
        var okHint = questionContainer.find(".ok-hint");

        var allFilledOut = true;
        var elems = questionContainer.find(".apply-required").toArray();
        elems.forEach(function(el) {
            var jqelem = $(el);
            if(jqelem.val().trim() === "") {
                allFilledOut = false;
            }
        });

        if(!allFilledOut) {
            if(!okHint.hasClass("ok-hint-disabled")) {
                okHint.addClass("ok-hint-disabled");
            }
        }
        else {
            if(okHint.hasClass("ok-hint-disabled")) {
                okHint.removeClass("ok-hint-disabled");
            }
        }

        updateProgress();
    });

    $("#apply-button").click(function() {
        $("#form-container").show();
        $("#header-container").slideUp({
            duration: 300,
            complete: function() {
                formActive = true;
                linkQuestions();
                setActiveQuestion(questions[0]);
                activeQuestion.find("input:text, textarea").first().focus();

                $("#header-container").hide();
                setTimeout(function() {
                    $("#form-progress").slideDown(300);
                }, 100);
            }
        });
    });
    $("select[name=state]").select2({
        placeholder: "Type or select an option",
        theme: "flat",
        width: "100%"
    });
    $(".apply-select:not(select[name=state]):not(select.trade-select)").select2({
        placeholder: "Type or select an option",
        theme: "flat",
        width: "100%"
    });
    $("select[name=supplierTrade]").select2({
        placeholder: "Type or select an option",
        theme: "flat",
        width: "100%"
    });
    $("select[name=contractorTrade]").select2({
        placeholder: "Type or select an option",
        theme: "flat",
        width: "100%"
    });
    $("select.trade-select:not(select[name=supplierTrade]):not(select[name=contractorTrade])").select2({
        placeholder: "Type or select an option (optional)",
        theme: "flat",
        width: "100%"
    });

    $(".apply-select:not(select.trade-select)").on("select2:selecting", function() {
        var wrapper = $(this).parents(".apply-select-wrapper");
        if(!wrapper.hasClass("selection-made")) {
            wrapper.addClass("selection-made");
        }

        updateProgress();

        var questionContainer = $(this).parents(".apply-question");
        var okHint = questionContainer.find(".ok-hint");
        if(okHint.hasClass("ok-hint-disabled")) {
            okHint.removeClass("ok-hint-disabled");
        }

        nextMoveTimeout = setTimeout(function() {
            triggerNextQuestion();
        }, choiceDelay);
    });

    $("select[name=supplierTrade], select[name=contractorTrade]").on("select2:selecting", function() {
        var wrapper = $(this).parents(".apply-select-wrapper");
        if(!wrapper.hasClass("selection-made")) {
            wrapper.addClass("selection-made");
        }

        updateProgress();

        var questionContainer = $(this).parents(".apply-question");
        var okHint = questionContainer.find(".ok-hint");
        if(okHint.hasClass("ok-hint-disabled")) {
            okHint.removeClass("ok-hint-disabled");
        }

        setTimeout(function() {
            $(".select2-container-active").removeClass("select2-container-active");
            $(":focus").blur();
        }, 1);
    });

    $("select[name=supplierTrade]").on("select2:selecting", function() {
        $(".supplierTrade2-container").show();
    });
    $("select[name=supplierTrade2]").on("select2:selecting", function() {
        $(".supplierTrade3-container").show();
    });
    $("select[name=supplierTrade3]").on("select2:selecting", function() {
        $(".supplierTrade4-container").show();
    });
    $("select[name=supplierTrade4]").on("select2:selecting", function() {
        $(".supplierTrade5-container").show();
    });

    $("select[name=contractorTrade]").on("select2:selecting", function() {
        $(".contractorTrade2-container").show();
    });
    $("select[name=contractorTrade2]").on("select2:selecting", function() {
        $(".contractorTrade3-container").show();
    });
    $("select[name=contractorTrade3]").on("select2:selecting", function() {
        $(".contractorTrade4-container").show();
    });
    $("select[name=contractorTrade4]").on("select2:selecting", function() {
        $(".contractorTrade5-container").show();
    });

    // Scroll logic.
    var windowJQ = $(window);

    window.addEventListener("scroll", function() {
        if(!formActive) {
            return;
        }

        if(nextMoveTimeout !== null) {
            clearTimeout(nextMoveTimeout);
            nextMoveTimeout = null;
        }

        var scrollTop = windowJQ.scrollTop();

        if(scrollTop <= 0) {
            scrollTop = 1;
        }

        var scrollBottom = scrollTop + windowJQ.height();
        var winh = windowJQ.height();

        var iter = questions[0];
        var currentActive = null;

        while(iter !== undefined && iter !== null) {
            var questionTop = iter.offset().top;
            if(questionTop <= scrollBottom - winh / 3) {
                currentActive = iter;
            }
            iter = iter.nextQuestion;
        }

        if(currentActive === questions[questions.length - 1] && $("#submit-section").offset().top <= scrollBottom - winh / 2) {
            currentActive = null;
            setActiveQuestion(null);
        }
        else if(currentActive !== null) {
            if(currentActive !== activeQuestion) {
                if(window.innerWidth >= 700) {
                    currentActive.find("input:text, textarea").first().focus();
                }
            }
            if(currentActive.hasClass("inactive-question")) {
                currentActive.removeClass("inactive-question");
            }
            setActiveQuestion(currentActive);
        }

        for(var i = 0, n = questions.length; i < n; i += 1) {
            var question = questions[i];
            if(question !== currentActive) {
                if(!question.hasClass("inactive-question")) {
                    question.addClass("inactive-question");
                }
                if(window.innerWidth > 900) {
                    question.find("*").blur();
                }
            }
        }
    },
    {
        passive: true
    });

    $(".apply-choice").click(function(e) {
        e.preventDefault();

        var elem = $(this).parents(".apply-input-wrapper").find(".choice");
        if(elem.length > 0) {
            elem.removeClass("choice");
        }

        $(this).addClass("choice");

        nextMoveTimeout = setTimeout(function() {
            triggerNextQuestion();
        }, choiceDelay);
    });

    $(".apply-confirm-patch").click(function(e) {
        e.preventDefault();

        var elem = $(this);
        elem.find(".apply-confirm-choice-button").html("✓");
        elem.addClass("apply-confirm-choice-selected");

        updateProgress();

        nextMoveTimeout = setTimeout(() => triggerNextQuestion(), choiceDelay);
    });
    $(".apply-confirm-choice-yes:not(.apply-confirm-choice-yes.apply-confirm-patch)").click(function(e) {
        e.preventDefault();

        const isEdit = $(this).parents(".apply-threeconfirm").find(".apply-confirm-choice-selected").length === 3;

        var elem = $(this);
        elem.find(".apply-confirm-choice-button").html("✓ YES");
        elem.addClass("apply-confirm-choice-selected");

        var noElem = $(this).parents(".apply-confirm-choice").find(".apply-confirm-choice-no");
        noElem.find(".apply-confirm-choice-button").html("NO");
        noElem.removeClass("apply-confirm-choice-selected");

        updateProgress();

        const selectedCount = $(this).parents(".apply-threeconfirm").find(".apply-confirm-choice-selected").length;
        if(selectedCount === 3 && !isEdit) {
            nextMoveTimeout = setTimeout(() => triggerNextQuestion(), choiceDelay);
        }
    });
    $(".apply-confirm-choice-no").click(function(e) {
        e.preventDefault();

        const isEdit = $(this).parents(".apply-threeconfirm").find(".apply-confirm-choice-selected").length === 3;

        var elem = $(this);
        elem.find(".apply-confirm-choice-button").html("✓ NO");
        elem.addClass("apply-confirm-choice-selected");

        var yesElem = $(this).parents(".apply-confirm-choice").find(".apply-confirm-choice-yes");
        yesElem.find(".apply-confirm-choice-button").html("YES");
        yesElem.removeClass("apply-confirm-choice-selected");

        updateProgress();

        const selectedCount = $(this).parents(".apply-threeconfirm").find(".apply-confirm-choice-selected").length;
        if(selectedCount === 3 && !isEdit) {
            nextMoveTimeout = setTimeout(() => triggerNextQuestion(), choiceDelay);
        }
    });

    $("#supplierChoice").click(function() {
        $("select[name=contractorTrade]").val(null);
        $("select[name=contractorTrade]").trigger("change");
        $("select[name=contractorTrade2]").val(null);
        $("select[name=contractorTrade2]").trigger("change");
        $("select[name=contractorTrade3]").val(null);
        $("select[name=contractorTrade3]").trigger("change");
        $("select[name=contractorTrade4]").val(null);
        $("select[name=contractorTrade4]").trigger("change");
        $("select[name=contractorTrade5]").val(null);
        $("select[name=contractorTrade5]").trigger("change");

        $(".contractorTrade-container").removeClass("selection-made");
        $(".contractorTrade2-container").hide();
        $(".contractorTrade3-container").hide();
        $(".contractorTrade4-container").hide();
        $(".contractorTrade5-container").hide();

        $(".contractor-specific input").val(null);
        $(".contractor-specific select").val(null);
        $(".supplier-specific, .contractor-specific").hide();
        $(".supplier-specific").show();
        linkQuestions();
        updateProgress();
        $(".confirm-last").text("I can meet all requirements set forth in Wonder Works' standard contract.");
        $("#taxNote").text("If not, Wonder Works will pay Use Tax directly for material sales in respective states.");
    });
    $("#contractorChoice").click(function() {
        $("select[name=supplierTrade]").val(null);
        $("select[name=supplierTrade]").trigger("change");
        $("select[name=supplierTrade2]").val(null);
        $("select[name=supplierTrade2]").trigger("change");
        $("select[name=supplierTrade3]").val(null);
        $("select[name=supplierTrade3]").trigger("change");
        $("select[name=supplierTrade4]").val(null);
        $("select[name=supplierTrade4]").trigger("change");
        $("select[name=supplierTrade5]").val(null);
        $("select[name=supplierTrade5]").trigger("change");

        $(".supplierTrade-container").removeClass("selection-made");
        $(".supplierTrade2-container").hide();
        $(".supplierTrade3-container").hide();
        $(".supplierTrade4-container").hide();
        $(".supplierTrade5-container").hide();

        $(".supplier-specific input").val(null);
        $(".supplier-specific select").val(null);
        $(".supplier-specific, .contractor-specific").hide();
        $(".contractor-specific").show();
        linkQuestions();
        updateProgress();
        $(".confirm-last").text("I can meet all requirements set forth in Wonder Works' standard contract, and will provide and accept ST-124 form for capital improvement work.");
        $("#taxNote").text("If not, Wonder Works will pay Use Tax directly for material sales in respective states. This does not apply to labor on capital improvement work.");
    });
    $("#bothChoice").click(function() {
        $(".supplier-specific, .contractor-specific").hide();
        $(".supplier-specific, .contractor-specific").show();
        linkQuestions();
        updateProgress();
        $(".confirm-last").text("I can meet all requirements set forth in Wonder Works' standard contract, and will provide and accept ST-124 form for capital improvement work.");
        $("#taxNote").text("If not, Wonder Works will pay Use Tax directly for material sales in respective states. This does not apply to labor on capital improvement work.");
    });

    $(".file-input-wrap").on("dragleave drop", function() {
        let file = $(this).find("input[type=file]").get(0).files;
        if(file && file.length === 0) file = null;
        let textClass = file ? ".post-upload-text" : ".pre-upload-text";
        $(this).removeClass("drag-highlight");
        $(this).find(textClass).show();
        $(this).find(".drop-text").hide();
    });
    $(".file-input-wrap").on("dragenter", function() {
        let file = $(this).find("input[type=file]").get(0).files;
        if(file && file.length === 0) file = null;
        let textClass = file ? ".post-upload-text" : ".pre-upload-text";
        $(this).addClass("drag-highlight");
        $(this).find(textClass).hide();
        $(this).find(".drop-text").show();
    });

    $(".apply-dropzone input[type=file]").change(function() {
        var zone = $(this).parents(".apply-dropzone");
        if(!zone.hasClass("uploaded")) {
            zone.addClass("uploaded");
        }
        zone.find(".post-upload-text").show();
        zone.find(".pre-upload-text").hide();

        var files = $(this).get(0).files;
        if(files !== undefined && files !== null) {
            const invalidFiles = [];

            // Validate in case of drop.
            for(i = 0, n = files.length; i < n; i += 1) {
                const file = files[i];
                if(ACCEPTED_DOCUMENT_TYPES.indexOf(file.type) === -1) {
                    invalidFiles.push(file.name);
                }
            }

            if(invalidFiles.length > 0) {
                let message = "";

                if(invalidFiles.length === 1) {
                    message += "File '" + invalidFiles[0] + "' is invalid. ";
                }
                else {
                    message += "The following files are invalid:\n\n" + invalidFiles.map(f => "• " + f).join("\n") + "\n\n";
                }

                message += "Accepted file types are " + ACCEPTED_DOCUMENT_TYPES_DISPLAY;

                zone.find(".post-upload-text").hide();
                zone.find(".pre-upload-text").show();
                zone.removeClass("uploaded");
                $(this).val("");
                updateProgress();
                alert(message);
                return;
            }
        }

        if(files !== undefined && files !== null && files.length > 1) {
            var str = "";
            var i, n;
            var f, name;

            if(files.length > 6) {
                for(i = 0, n = files.length; i < n; i += 2) {
                    var f2 = i + 1 < n ? files[i + 1] : null;
                    f = files[i];

                    name = f.name.split("\\").pop().split("/").pop();
                    str += name;

                    if(f2) {
                        str += ", " + f2.name.split("\\").pop().split("/").pop();                        
                    }

                    if(i !== n - 1) {
                        str += "<br/>";
                    }
                }
            }
            else {
                for(i = 0, n = files.length; i < n; i += 1) {
                    f = files[i];
                    name = f.name.split("\\").pop().split("/").pop();
                    str += name;

                    if(i !== n - 1) {
                        str += "<br/>";
                    }
                }
            }

            if(files.length > 10) {
                zone.find(".post-upload-text").hide();
                zone.find(".pre-upload-text").show();
                zone.removeClass("uploaded");
                $(this).val("");
                updateProgress();
                alert("Please reduce the number of selected documents to 10.");
                return;
            }
            else {
                zone.find(".filename").html(str);
            }
        }
        else {
            zone.find(".filename").text($(this).val().split("\\").pop().split("/").pop());
        }

        updateProgress();

        nextMoveTimeout = setTimeout(function() {
            triggerNextQuestion();
        }, choiceDelay);
    });

    // Reformat the tax id value in real-time.
    $(".validate-tax-id").on("input", function() {
        var value = $(this).val();
        var newValue = "";
        var maxlen = 10; // 9 digits + dash.

        var i, n;
        for(i = 0, n = Math.min(value.length, maxlen); i < n; i += 1) {
            var ch = value.charAt(i);
            if(i === 2) {
                newValue += "-";
                if(ch === "-") {
                    continue;
                }
            }
            if(!isNaN(ch)) {
                newValue += ch;
            }
        }

        $(this).val(newValue);
        updateProgress();
    });

    $(document).keypress(function(e) {
        if(e.which == 13 && formActive) {
            if(formActive) {
                triggerEnterNextQuestion();
            }
        }
    });
    $(".ok-hint .the-button").on("click", function(e) {
        e.preventDefault();
        triggerEnterNextQuestion(false);
    });

    /////////////////////////////////////////////////////////////////////////////////////
    // PREVENT FORM SUBMIT ON ENTER KEY
    /////////////////////////////////////////////////////////////////////////////////////

    $("form").on("keyup", function(e) {
        var keyCode = e.keyCode || e.which;
        if(keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });
    $("form").on("keypress", function(e) {
        var keyCode = e.keyCode || e.which;
        if(keyCode === 13) {
            if(!e.shiftKey) {
                if(formActive) {
                    triggerEnterNextQuestion();
                }
            }
            e.preventDefault();
            return false;
        }
    });

    /////////////////////////////////////////////////////////////////////////////////////
    // DRAG AND DROP FILES
    /////////////////////////////////////////////////////////////////////////////////////
    // Prevent the browser from opening the dragged file if dropped outside dropzone.

    window.addEventListener("dragenter", function(e) {
        e = e || event;
        if(!$(e.target).hasClass("dropable")) {
            e.preventDefault();
            e.dataTransfer.effectAllowed = "none";
            e.dataTransfer.dropEffect = "none";
        }
    }, false);

    window.addEventListener("dragover", function(e) {
        e = e || event;
        if(!$(e.target).hasClass("dropable")) {
            e.preventDefault();
            e.dataTransfer.effectAllowed = "none";
            e.dataTransfer.dropEffect = "none";
        }
    });

    window.addEventListener("drop", function(e) {
        e = e || event;
        if(!$(e.target).hasClass("dropable")) {
            e.preventDefault();
            e.dataTransfer.effectAllowed = "none";
            e.dataTransfer.dropEffect = "none";
        }
    });

    /////////////////////////////////////////////////////////////////////////////////////
    // SUBMIT FORM
    /////////////////////////////////////////////////////////////////////////////////////

    function submitFormData(formData) {
        return new Promise((resolve, reject) => {
            const xhttp = new XMLHttpRequest();
            xhttp.timeout = 10 * 60 * 1000;
            xhttp.onload = function() {
                if(this.status === 200) {
                    resolve(xhttp.response);
                }
                else {
                    const error = new Error("Post request failed");
                    error.status = this.status;
                    error.response = xhttp.response;
                    reject(error);
                }
            };
            xhttp.ontimeout = function() {
                const error = new Error("Timeout");
                reject(error);
            };
            xhttp.onabort = function() {
                reject(new Error("Request failed"));
            };
            xhttp.onerror = function() {
                reject(new Error("Request failed"));  
            };
            xhttp.open("POST", window.location.pathname);

            try {
                xhttp.send(formData);
            }
            catch(e) {
                reject(e);
            }
        });
    }

    $("form").submit(function(e) {
        // Hide error tips if any is shown.
        var tip = $(".apply-submit-errortip");
        tip.hide();

        // Iterate over the questions and store the problematic ones.
        var q = questions[0];
        var problematic = [];
        while(q !== null && q !== undefined) {
            if(!checkQuestion(q)) {
                problematic.push(q);
            }
            q = q.nextQuestion;
        }

        // Form will be submitted over ajax.
        e.preventDefault();

        if(problematic.length > 0) {            
            if(problematic.length === 1) {
                tip.text(problematic.length + " field needs completing.");
            }
            else {
                tip.text(problematic.length + " fields needs completing.");
            }
            tip.slideDown(200);

            $("html, body").animate({
                scrollTop: $(document).height()
            }, 200);
        }
        else {
            let totalFiles = 0;

            const files0 = $("input[name=w9]").prop("files");
            const files1 = $("input[name=generalInsurance]").prop("files");
            const files2 = $("input[name=umbrellaInsurance]").prop("files");
            const files3 = $("input[name=workerInsurance]").prop("files");
            const files4 = $("input[name=autoInsurance]").prop("files");

            totalFiles += files0 ? files0.length : 0;
            totalFiles += files1 ? files1.length : 0;
            totalFiles += files2 ? files2.length : 0;
            totalFiles += files3 ? files3.length : 0;
            totalFiles += files4 ? files4.length : 0;

            if(totalFiles > 50) {
                tip.text("Please reduce the number of selected documents, you can only send 50 documents at a time.");
                tip.slideDown(200);

                $("html, body").animate({
                    scrollTop: $(document).height()
                }, 200);
                return;
            }

            const noInsurance1 = (!files1 || files1.length === 0);
            if(noInsurance1) $("input[name=generalInsurance]").remove();

            const noInsurance2 = (!files2 || files2.length === 0);
            if(noInsurance2) $("input[name=umbrellaInsurance]").remove();

            const noInsurance3 = (!files3 || files3.length === 0);
            if(noInsurance3) $("input[name=workerInsurance]").remove();

            const noInsurance4 = (!files4 || files4.length === 0);
            if(noInsurance4) $("input[name=autoInsurance]").remove();

            var formData = new FormData($("form").get(0));

            if(noInsurance1) formData.append("generalInsurance", new Blob(["ASD"], { type: "text/plain" }), "aSD");
            if(noInsurance2) formData.append("umbrellaInsurance", new Blob(["ASD"], { type: "text/plain" }), "aSD");
            if(noInsurance3) formData.append("workerInsurance", new Blob(["ASD"], { type: "text/plain" }), "aSD");
            if(noInsurance4) {
                formData.append("ignoreAuto", "true");
                formData.append("autoInsurance", new Blob(["ASD"], { type: "text/plain" }), "aSD");
            }

            var addTaxField = function(elementId, fieldName) {
                if($("#" + elementId + " .apply-confirm-choice-yes.apply-confirm-choice-selected").length > 0) {
                    formData.append(fieldName, "true");
                }
                else {
                    formData.append(fieldName, "false");
                }
            };

            addTaxField("taxNY", "taxNY");
            addTaxField("taxNJ", "taxNJ");
            addTaxField("taxCT", "taxCT");

            $("#submit-form-button").hide();
            $("#loading-animation").fadeIn(200);

            submitFormData(formData)
                .then(() => {
                    $("#loading-animation").fadeOut(200);
                    formActive = false;
                    setTimeout(function() {
                        $("#footer-container").show();
                        $("#form-progress").fadeOut(200);
                        $("#form-container").slideUp({
                            duration: 600,
                            complete: function() {
                                $("#form-container").hide();
                            }
                        });
                    }, 200);
                })
                .catch(e => {
                    let errorText = "Unexpected error happened. Please try again.";

                    if(e.response && e.status === 400) {
                        try {
                            const data = JSON.parse(e.response);
                            if(data.error) {
                                errorText = data.error.join("\n");
                            }
                        }
                        catch(e) {
                            console.error(e);
                            errorText += " Failed to process server error message";
                        }
                    }
                    if(e.status) {
                        console.log("STATUS: " + e.status);
                    }

                    $("#loading-animation").hide();
                    $("#submit-form-button").show();
                    tip.text(errorText);
                    tip.html(tip.html().replace(/\n/g,"<br/>"));
                    tip.slideDown(200);
                });

            // $.ajax({
            //     url: window.location.pathname,
            //     type: "POST",
            //     data: formData,
            //     processData: false,
            //     contentType: false,
            //     cache: false,
            //     success: function() {
                    
            //     },
            //     error: function(response) {
            //         $("#loading-animation").hide();
            //         $("#submit-form-button").show();
            //         tip.text("Unexpected error happened. Please try again.");
            //         tip.slideDown(200);
            //     }
            // });
        }
    });

});
